<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-alert
          v-if="error_message.length > 0"
          show
          variant="danger"
        >
          <div class="alert-body">
            <div
              v-for="(item, index) in error_message"
              :key="index"
              class="my-50"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span class="ml-25">{{ item }}</span>
            </div>
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card-code
          no-body
          title="Import"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label="Pilih Divisi"
                  label-for="divisi"
                >
                  <b-form-select
                    id="divisi"
                    v-model="dataInput.divisi"
                    :options="divisi"
                  />
                </b-form-group>
                <b-form-group
                  label="Upload File"
                  label-for="upload-file"
                >
                  <b-form-file
                    v-model="dataInput.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="submit()"
                >
                  Edit
                </b-button>
                <b-button
                  variant="secondary"
                  class="ml-1"
                  @click="gotoMember()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCardBody,
  BFormGroup, BFormSelect, BFormFile, BButton, BAlert,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BButton,
    BAlert,
  },
  data() {
    return {
      dataInput: {
        divisi: null,
        file: null,
      },
      divisi: [],
      active_role: null,
      error_message: [],
    }
  },
  created() {
    this.checkAuth()
  },
  mounted() {
    this.active_role = localStorage.getItem('role')
    this.getDataDivisi()
  },
  methods: {
    async getDataDivisi() {
      const cid = localStorage.getItem('client_id')

      let dataAPI = null
      if (this.active_role === 'corporate') {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        dataAPI = await this.$axios.get(`${cid}/member/corporate/divisi`, config)
      }

      dataAPI.data.data.forEach(item => {
        const divisiItem = {
          value: item.id,
          text: item.divisi_name,
        }

        this.divisi.push(divisiItem)
      })
    },

    gotoMember() {
      this.$router.push({ path: '/user/member' })
    },

    submit() {
      this.error_message = []
      const cid = localStorage.getItem('client_id')

      if (this.dataInput.file == null) {
        this.runToast('error', 'File wajib diisi')
      } else if (this.dataInput.divisi == null) {
        this.runToast('error', 'Divisi wajib diisi')
      } else {
        const data = new FormData()
        data.append('file', this.dataInput.file)
        data.append('divisi', this.dataInput.divisi)

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }

        this.$axios.post(`${cid}/member/import`, data, config)
          .then(() => {
            this.runToast('success', 'Import Data Success')

            setTimeout(() => {
              this.$router.push({ path: '/user/member' })
            }, 3000)
          })
          .catch(error => {
            const errorRes = error.response.data.data

            const groupingData = []
            errorRes.forEach(item => {
              const { row } = item

              groupingData[row] = groupingData[row] ?? []

              // Set up error message
              groupingData[row].push({
                row: item.row,
                message: item.errors[0],
                name: `${item.values.first_name} ${item.values.last_name}`,
              })
            })

            const result = groupingData.map(item => {
              let row = null
              const message = []
              let name = ''

              item.forEach(itemDetail => {
                row = itemDetail.row
                message.push(itemDetail.message)
                name = itemDetail.name
              })

              const joinArray = message.join()
              const replaceDot = joinArray.replaceAll('.', '')
              const replaceComma = replaceDot.replaceAll(',', ', ')
              const setLower = replaceComma.toLowerCase()

              return `Error in row ${row} - ${name}, reason : ${setLower}`
            })

            // convert from object to array
            const convertObjToArr = Object.values(result)

            this.error_message = convertObjToArr
          })
      }
    },
    runToast(type, msg) {
      let title = null
      let icon = null
      let variant = null

      if (type === 'error') {
        title = 'Error'
        icon = 'XIcon'
        variant = 'danger'
      } else if (type === 'success') {
        title = 'Success'
        icon = 'CheckIcon'
        variant = 'success'
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: msg,
          variant,
        },
      }, {
        position: 'bottom-right',
      })
    },
  },
}
</script>
